// Variables
@import 'variables-front.scss';

// Bootstrap
@import '~bootstrap/scss/mixins/_breakpoints.scss';

//Slider
@import '~owl.carousel/dist/assets/owl.carousel.css';
@import '~owl.carousel/dist/assets/owl.theme.default.css';


#owl-carousel-weather.owl-carousel{
	.owl-nav button{
		&.owl-prev,
		&.owl-next{
			background:$blue;
			border-radius:50%;
			color:$white;
			position: absolute;
			top: 40px;
			padding: 6px 20px !important;
			font-size: 24px;
			max-width:50px;
			max-height:50px;
		}
		&.owl-prev {
			left: -65px;
		}
		&.owl-next {
			right: -65px;
		}
	}
} 

#owl-carousel-comments.owl-theme{
	.owl-stage{
		display:flex;
		.owl-item{
			min-height:100%;
			.card{
				min-height:100%;
			}
		}
	}
	.owl-nav button{
		&.owl-prev,
		&.owl-next{
			background:$blue;
			border-radius:50%;
			color:$white;
			position: absolute;
			top: 35%;
			padding: 6px 20px !important;
			font-size: 24px;
			max-width:50px;
			max-height:50px;
		}
		&.owl-prev {
			left: -65px;
		}
		&.owl-next {
			right: -65px;
		}
	}	
	.owl-dots .owl-dot{
		span {
			background:$white;
		}
		&.active span{
			background:$yellow
		}
	}
}

#carouselHotel{
	.carousel-item{
		.photo{
			object-fit:cover;
			object-position:center;
			height:100%;
			width:100%;
		}
	}
}

@include media-breakpoint-down(lg) {
	#owl-carousel-comments.owl-theme, 
	#owl-carousel-weather.owl-carousel{
		.owl-nav button{
			&.owl-prev,
			&.owl-next{
				background:$blue;
				border-radius:50%;
				color:$white;
				position: absolute;
				top: 40px;
				padding: 6px 15px !important;
				font-size: 20px;
				max-width:40px;
				max-height:40px;
			}
			&.owl-prev {
				left: -45px;
			}
			&.owl-next {
				right: -45px;
			}
		}
	} 
}

@include media-breakpoint-down(lg) {
	#carouselHotel{
		.carousel-item{
			max-height:80vh;
		}
	}
}